import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { useOutsideClick } from '../hooks'

const Container = styled.div`
  position: relative;

  button {
    color: inherit;
    background: inherit;
    border: none;
  }

  ul {
    position: absolute;
    top: calc(100% + 4px);
    right: 8px;
    width: 170px;
    padding: 5px;
    margin: 0;
    border-radius: 17px;
    color: var(--text-primary);
    background: white;
    list-style: none;
    box-shadow: 2px 6px 6px 0 rgba(0, 0, 0, 0.2);

    &:not(.open) {
      opacity: 0;
      pointer-events: none;
    }

    a {
      background: transparent;

      &:hover {
        background: var(--text-muted);
      }
    }
  }
`

export const Dropdown = (props) => {
  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)

  const ulRef = useRef()
  useOutsideClick(ulRef, () => {
    setOpen(false)
  })

  const containerClass = [open && 'open', props.containerClassName]
    .filter((klass) => !!klass)
    .join(' ')
  const buttonClass = [open && 'open', props.buttonClassName]
    .filter((klass) => !!klass)
    .join(' ')
  const listClass = [open && 'open', props.listClassName]
    .filter((klass) => !!klass)
    .join(' ')

  return (
    <Container className={containerClass}>
      <button className={buttonClass} onClick={toggleOpen}>
        {props.button}
      </button>
      <ul className={listClass} ref={ulRef}>
        {props.children}
      </ul>
    </Container>
  )
}
