import React from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { Breadcrumbs } from './Breadcrumbs'
import { SpinnerRect } from './Spinner'

const TitleContainer = styled.div`
  padding: 18px 40px 0;
  background: var(--body-background-darken);

  .breadcrumbs {
    margin-bottom: 16px;
  }

  .breadcrumbs-spacer {
    margin-bottom: 18px;

    & + h1,
    & + .h1-spacer {
      margin-bottom: 16px;
    }
  }

  h1 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
  }

  .h1-spacer {
    height: 31px;
  }

  .back-link {
    position: relative;
    top: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    margin-right: 8px;
    color: var(--text-primary);

    svg {
      max-width: 22px;
      max-height: 22px;
    }
  }

  & + [class*="ContentContainer"] {
    padding-top: 20px;
  }
`

export const PageTitle = (props) => {
  const { title, backPath, crumbs } = props

  return (
    <TitleContainer>
      {crumbs ? (
        <Breadcrumbs crumbs={crumbs} />
      ) : (
        <div className="breadcrumbs-spacer" />
      )}

      {title ? (
        <h1>
          {backPath && (
            <a href={backPath} className="back-link">
              <SVG src="/images/icons/arrow-left.svg" />
            </a>
          )}
          {title}
        </h1>
      ) : (
        <div className="h1-spacer" />
      )}

      {props.children}
    </TitleContainer>
  )
}

const ContentContainer = styled.div`
  padding: 40px 40px;
  background: var(--body-background);
`

export const PageContent = (props) => {
  const { className } = props

  return (
    <ContentContainer className={className}>{props.children}</ContentContainer>
  )
}

export const PageLoading = (props) => {
  return (
    <div>
      {props.title !== false && <PageTitle {...props} />}

      <ContentContainer>
        <div className="text-center">
          <SpinnerRect />
        </div>
      </ContentContainer>
    </div>
  )
}
