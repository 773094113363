import { compact } from 'lodash'
import { navigate } from 'gatsby'
import money from 'money-math'
import moment from 'moment'
import { formatStatValue } from './stats'
import { getCurrentUser } from '../../services/auth'
import { getCurrentCurrency } from '../currencies'

export * from './chart'
export * from './stats'
export * from './form'

export const navigateTo = (event, target) => {
  if (!target && typeof event === 'string') {
    navigate(event)
  } else if (event.ctrlKey || event.metaKey || event.which === 2) {
    window.open(target, '_blank')
  } else {
    navigate(target)
  }
}

export const isHeaderlessLayout = () =>
  window.location.pathname.match(/^(\/|\/signup|\/forgot-password)\/?$/)

export const toQuery = (obj) =>
  Object.entries(obj)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&')

export const parseQuery = () => {
  const result = {}
  const pairs = window.location.search.substring(1).split('&')
  let pair = {}

  for (let i in pairs) {
    if (pairs[i] === '') continue

    pair = pairs[i].split('=')
    result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
  }

  return result
}

export const copyToClipboard = (targetSelector, callback) => (event) => {
  event.preventDefault()
  const target = document.querySelector(targetSelector)

  if (target) {
    const selection = window.getSelection()
    const range = document.createRange()

    range.selectNode(target)
    selection.removeAllRanges()
    selection.addRange(range)

    document.execCommand('copy')

    selection.removeAllRanges()
  }

  if (callback) {
    callback()
  }
}

export const getExtendedContractStatus = (record) => {
  if (record.terminatedAt) {
    return 'terminated'
  } else if (record.endedAt) {
    return 'ended'
  } else if (record.pausedAt) {
    return 'paused'
  } else {
    return record.status
  }
}

export const getInitials = (name) =>
  name
    .split(' ')
    .map((name) => name[0].toUpperCase())
    .join('')

export const capitalize = (str) => str[0].toUpperCase() + str.substring(1)

export const formatDate = (date) =>
  moment(date || undefined).format('MMMM DD, YYYY')

export const formatDatetime = (date) =>
  moment(date || undefined).format('YYYY-MM-DD hh:mm:ss') || ''

export const formatPeriod = (from, till) =>
  moment(from || undefined).format('MMMM DD, YYYY') +
  ' — ' +
  moment(till || undefined).format('MMMM DD, YYYY')

export const formatTimeToAction = (conversion) => {
  if (!conversion.datetime || !conversion.session_datetime) {
    return ''
  }
  const duration = moment.duration(
    moment(conversion.datetime).diff(moment(conversion.session_datetime))
  )
  const hours = duration.hours().toString().padStart(2, '0')
  const minutes = duration.minutes().toString().padStart(2, '0')
  const seconds = duration.seconds().toString().padStart(2, '0')
  return `${hours}:${minutes}:${seconds}`
}

export const defaultCurrency = 'USD'

export const convertMoney = (value, currency) => {
  const rates = getCurrentUser()?.currencyRates
  if (!rates || !rates[currency]) return [value, currency]
  return [value * rates[currency], getCurrentCurrency()]
}

export const formatMoney = (value, currency) => {
  const [convertedValue, convertedCurrency] = convertMoney(value, currency)
  return `${convertedCurrency || currency || defaultCurrency} ${formatStatValue(
    money.floatToAmount(convertedValue || value || 0),
    true
  )}`
}

export const formatMoneyCents = (valueCents, currency) => {
  const [convertedValueCents, convertedCurrency] = convertMoney(
    valueCents,
    currency
  )
  return `${convertedCurrency || currency || defaultCurrency} ${formatStatValue(
    money.floatToAmount((convertedValueCents || 0) / 100),
    true
  )}`
}

export const formatInvoiceName = (invoice) => {
  switch (invoice.type) {
    case 'commission':
      return `Partner fee, ${invoice.period}`
    case 'fee':
      return `Monthly fee, ${invoice.period}`
    default:
      return null
  }
}

export const formatInvoiceBalance = (invoice) => {
  switch (invoice.type) {
    case 'commission':
      return formatMoney(parseFloat(invoice.data.totalPayout), invoice.currency)
    case 'fee':
      return formatMoney(parseFloat(invoice.data.totalFee), invoice.currency)
    default:
      return null
  }
}

export const formatAddress = (values) =>
  compact([values.address, values.zip, values.city]).join(', ')

export const formatContractFilename = (contract) => {
  const code = contract.code.replace(/\W/g, '').toLowerCase()
  const year = new Date(contract.createdAt).getFullYear()
  return `${code}_contract${year}.pdf`
}

export const formatAppendixFilename = (contract, appendix) => {
  const contractCode = contract.code.replace(/\W/g, '').toLowerCase()
  const appendixCode = appendix.code.replace(/\W/g, '').toLowerCase()
  const year = new Date(appendix.createdAt).getFullYear()
  return `${contractCode}_${appendixCode}${year}.pdf`
}
